import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms of use for our app`}</h1>
    <p>{`Last updated: August 25, 2022`}</p>
    <h2>{`1. Scope of Application`}</h2>
    <p>{`1.1 These Terms of Use / General Terms and Conditions ("Terms") apply to the use of our mobile iPhone and Android app VANATU ("App", "Services") by you ("User", "you"). The Services are provided by relayts UG (haftungsbeschränkt), Beskidenstr. 18A, 14129 Berlin, Germany ("we", "us").`}</p>
    <p>{`1.2 The App offers you the possibility to connect with other App Users using location-based information.
You can read and share information from other App Users via local communities,
as well as create local communities yourself. Based on GPS location, you can see the nearest
local communities as well as profiles of other Users. When a match is made, you can chat with other App Users
and see if they are at a local VANATU community based on their GPS location. All other VANATU users you have not created a match with can only see you in the app within a 100m radius. Also, you can create a public profile and send messages. Once you post content on
the App, it will be visible to other Users.`}</p>
    <h2>{`2. Agreement conclusion, user account, payment`}</h2>
    <p>{`2.1 When you open the app for the first time, you must accept these terms and conditions in order to use the app. By accepting the terms and conditions (e.g. by clicking on "Sign up with Google", "Sign up with Apple" or "Sign up with email") you are
sending an offer to enter into a contract for the use of the App ("Contract"). We accept your offer by providing you with our Services.`}</p>
    <p>{`2.2 Only users who are at least 18 years old can create a user account and use the App. You will state your age during the registration process. If you are under 18 years old, you are unfortunately not allowed to use the app.`}</p>
    <p>{`2.3 The use of the services is free of charge for you.`}</p>
    <h2>{`3. Your right to use the app`}</h2>
    <p>{`3.1 You may only use the App as an end consumer and for non-commercial purposes.`}</p>
    <p>{`3.2 Once you have agreed to the Agreement, we will give you a limited, non-exclusive, non-transferable and non-licensable right to use the App. This is for the duration of the Agreement and the intended purpose of the App.`}</p>
    <h2>{`4. Our right to use your content`}</h2>
    <p>{`4.1 In order to provide our Services, you agree to allow us to use the content you provide to us ("User Content") and in particular content protected by intellectual property rights.`}</p>
    <p>{`4.2 Once you publish Content in the App, other Users in the App can access it. You can find more information in our `}<a parentName="p" {...{
        "href": "/app-privacy"
      }}>{`privacy policy`}</a>{`.`}</p>
    <h2>{`5. Obligations of the user(s)`}</h2>
    <p>{`You commit to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To observe all laws and other regulations as well as the rights of third parties when using the app.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To take responsibility for the publication of content (text, photos, links). You undertake that this content does not breach the rights of third parties, such as personal rights and copyrights. You indemnify us against all claims asserted against us by third parties on account of or in connection with the content published by you.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Not to publish any content that is unlawful, illegal or prohibited by these Terms of Use.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Not to publish any insulting, defamatory, morally offensive, discriminatory, obscene or pornographic content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`No illegal, in particular, pornographic, violence glorifying, racist, extremist abusive, terrorist, immoral content or content that violates the laws for the protection of minors.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Not to publish any illegal, in particular, pornographic, violence-glorifying, racist, extremist-abusive, terrorist, immoral or youth-protection content, to offer, advertise or distribute content, goods or services that are immoral or violate the laws for the protection of minors.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Take reasonable security measures to prevent your device and user account from being compromised by third parties.
In doing so, you need to make sure that only you have access to the app, that you have the latest updates for your device (updates for the operating system) and the latest updates for the app.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Refrain from unreasonable harassment of users, spam and chain letters.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Refrain from using fake locations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Refrain from any action that impairs the function of our app or infrastructure, places an excessive burden on it, attacks it or jeopardises security.`}</p>
      </li>
    </ul>
    <h2>{`6. Duration of Contract, Termination`}</h2>
    <p>{`6.1 The contract is concluded for an indefinite period.`}</p>
    <p>{`6.2 The contract may be terminated at any time without notice by either party.`}</p>
    <h2>{`7. Other rights reserved by us`}</h2>
    <p>{`7.1 We reserve the right to change or delete User Content. This applies in particular to User Content that violates legal provisions or these legal provisions or these Terms of Use and cause damage to us or third parties. The modification or deletion shall take place without prior notice.`}</p>
    <p>{`7.2 We reserve the right to exclude users and to deactivate or delete their user content and profile. This applies in particular to Users and User Content that violate legal provisions or these Terms of Use. The deactivation or deletion shall take place without prior notice.`}</p>
    <p>{`7.3 We reserve the right to report any indications of illegal activities of individual users as well as correspondingly suspicious content to the data protection authorities. In compliance with data protection law to the respective competent authorities.`}</p>
    <p>{`7.4 We reserve the right to place photos posted by users on publicly accessible areas of the application.`}</p>
    <h2>{`8. Responsibility for content`}</h2>
    <p>{`8.1 We assume no responsibility for user content. This also includes links provided by users to external websites and their content.`}</p>
    <p>{`8.2 Our services contain links to third-party websites. As we have no influence on their content, we cannot assume any liability.`}</p>
    <p>{`8.3 We ask you to report cases of abuse via the report function in the app or by email at `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{`.`}</p>
    <h2>{`9. Liability`}</h2>
    <p>{`We are liable to the user in case of injury to life, body and health as well as intent and gross negligence. Beyond this, liability is excluded to the extent permitted by law.`}</p>
    <h2>{`10. Availability`}</h2>
    <p>{`We endeavour to make our services permanently available. However, we do not guarantee availability. Our services may be unavailable for an indefinite period of time due to, for example, maintenance work, a system failure, security breaches or other events.`}</p>
    <h2>{`11. Data protection`}</h2>
    <p>{`We collect, process and use personal data. You can find more information in our `}<a parentName="p" {...{
        "href": "/app-privacy"
      }}>{`privacy policy`}</a>{`.`}</p>
    <h2>{`12. Change of the terms of use`}</h2>
    <p>{`12.1 We reserve the right to amend or change the Terms of Use. This can happen at any time and without reasons.`}</p>
    <p>{`12.2 As soon as the Terms of Use have changed, we will inform you when you re-open the App. You will then be asked to agree to the to agree to the amended Terms of Use.`}</p>
    <p>{`12.3 If you do not agree to the amended Terms of Use, you will no longer be able to use our services. The contract can therefore be terminated without notice period by either party to the contract.`}</p>
    <h2>{`13. Miscellaneous`}</h2>
    <p>{`These Terms of Use are governed by the laws of the Federal Republic of Germany.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      